"use client";

import { HTMLProps } from "react";
import clsx from "clsx";

import { getPageOrder } from "../../lib/pagination";
import { useMediaQuery } from "@/hooks/mediaQuery";
import { BODY_SMALL } from "@/styles";
import { ChevronLeft, ChevronRight } from "lucide-react";

export type PaginationProps = {
  currentPage: number;
  lastPage: number;
  setCurrentPage: (page: number) => void;
};

export type PageBtnProps = HTMLProps<HTMLButtonElement> & { active?: boolean };

const PageBtn = ({ className, active, disabled, children, onClick }: PageBtnProps) => {
  const customClassName = clsx(
    `page-link ${BODY_SMALL}`,
    active && "bg-black text-white pointer-events-none",
    className
  );

  if (disabled) {
    return (
      <button disabled className={customClassName}>
        {children}
      </button>
    );
  }

  return (
    <button className={customClassName} aria-current={active ? "page" : undefined} onClick={onClick}>
      {children}
    </button>
  );
};

const Pagination = ({ currentPage, lastPage, setCurrentPage }: PaginationProps) => {
  const isDesktop = useMediaQuery("(min-width: 640px)");
  const pages = getPageOrder(currentPage, lastPage, isDesktop);

  if (!lastPage) {
    return null;
  }

  return (
    <nav className="flex flex-wrap space-x-1" aria-label="Pagination">
      <PageBtn
        type="button"
        title="Previous Page"
        className="page-link-arrows"
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        <ChevronLeft size={20} className="mt-[2px]" aria-label="chevron left icon" />
        {isDesktop && "Previous"}
      </PageBtn>

      {pages.map((page, idx) => (
        <PageBtn
          type="button"
          title={`Page ${page}`}
          key={idx}
          active={currentPage === page}
          disabled={isNaN(page)}
          onClick={() => setCurrentPage(page)}
        >
          {!isNaN(page) ? page : "..."}
        </PageBtn>
      ))}

      <PageBtn
        type="button"
        title="Next Page"
        className="page-link-arrows"
        disabled={currentPage === lastPage}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        {isDesktop && "Next"}
        <ChevronRight size={20} className="mt-[2px]" aria-label="chevron right icon" />
      </PageBtn>
    </nav>
  );
};

export default Pagination;
